.A113 {
  background-color: grey;
  width: 100%;
  height: 6px;

  &.Mui-active {
    background-color: red;
  }

  &.Mui-completed {
    background-color: red;
  }
}
