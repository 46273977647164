@tailwind base;
@tailwind components;
@tailwind utilities;


.stepper-wrapper::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.stepper-wrapper {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}


.css-1hv8oq8-MuiStepLabel-label {
  font-size: 14px !important;
  font-family: "DM Sans", sans-serif;
  color: #98a2b3;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-completed {
  color: #667085 !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: #dd2590 !important;
}