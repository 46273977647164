.U2323.MuiListItemButton-root {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  &.Mui-selected {
    background-color: black !important;
    color: white;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  &:hover {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: rgba(0, 0, 0, 0.233) !important;
  }
}

.U23242.MuiListItemButton-root {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  &:hover {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: rgba(0, 0, 0, 0.233) !important;
  }
}
