.A113 {
  background-color: grey;
  width: 100%;
  height: 6px;
}
.A113.Mui-active {
  background-color: red;
}
.A113.Mui-completed {
  background-color: red;
} /*# sourceMappingURL=style.css.map */
