.Y23232,
.U232323,
.F32323,
.G4343 {
  text-transform: capitalize !important;
  font-size: 12px;
  border-style: solid !important;
  border-width: 1px !important;
}

.Y23232 {
  background-color: rgba(255, 0, 0, 0.2) !important;
  color: red !important;
}

.U232323 {
  background-color: rgba(255, 255, 0, 0.253) !important;
  color: yellowgreen !important;
}

.F32323 {
  background-color: rgba(0, 0, 255, 0.212) !important;
  color: blue !important;
}

.G4343 {
  background-color: rgba(0, 0, 0, 0.205) !important;
  color: black !important;
}
